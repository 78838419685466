import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import routes from './config/routeConfig'
import { isAuthenticated } from './helpers/auth'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route) => {
          const { component: Component, path, authenticated } = route

          if (authenticated) {
            return (
              <Route
                exact
                key={path}
                path={path}
                // component={
                //   isAuthenticated('router') ? Component : () => <Redirect to="/auth/login" />
                // }
                component={(props: any) => {
                  if (isAuthenticated()) {
                    return <Component {...props} />
                  } else {
                    return <Redirect to="/auth/login" />
                  }
                }}
              />
            )
          } else {
            return <Route exact key={path} path={path} component={Component} />
          }
        })}

        <Route path="*" component={() => <p>Not found</p>} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
