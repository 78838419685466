import React, { useEffect, useState } from 'react'
import BaseTable from '../../components/BaseTable/BaseTable'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { getAllCompanies } from './helper'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Link, RouteComponentProps } from 'react-router-dom'
import { CellProps } from 'react-table'

interface IProps extends RouteComponentProps {}

const CompaniesPage: React.FC<IProps> = ({ history }) => {
  const [companies, setCompanies] = useState<any[]>([])
  useEffect(() => {
    getAllCompanies().then((res) => {
      setCompanies(res.data)
    })
  }, [])

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <Link className="no-underline" to={`/company/view/${cellProps.row.original.id}`}>
            {cellProps.value}
          </Link>
        )
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Utilized Budget',
      accessor: 'utilized',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    {
      Header: 'Remaining Budget',
      accessor: 'currentBudget',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (cellProps: CellProps<any>) => {
        return (
          <FormControl>
            <InputLabel>Select an action</InputLabel>
            <Select className="w-40">
              <MenuItem
                value="edit"
                onClick={() => history.push(`/company/edit/${cellProps.value}`)}
              >
                Edit
              </MenuItem>

              <MenuItem
                value="view"
                onClick={() => history.push(`/company/view/${cellProps.value}`)}
              >
                View
              </MenuItem>
            </Select>
          </FormControl>
        )
      },
    },
  ]

  return (
    <DashboardWrapper page="company" title="Companies">
      <Button
        className="self-end"
        color="primary"
        variant="contained"
        type="submit"
        disableElevation
        onClick={() => history.push('/company/edit/new')}
      >
        Create A Company
      </Button>
      <BaseTable columns={columns} data={companies} />
    </DashboardWrapper>
  )
}

export default CompaniesPage
