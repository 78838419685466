import { Button, CircularProgress } from '@material-ui/core'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import React from 'react'
import { login } from './helpers'
import { isAuthenticated } from './../../helpers/auth'
import { Redirect, RouteComponentProps } from 'react-router'

interface IFormFields {
  email: string
  password: string
}

interface IProps extends RouteComponentProps {}

const LoginPage: React.FC<IProps> = ({ history }) => {
  if (isAuthenticated()) {
    return <Redirect to="/" />
  }

  const initialValues: IFormFields = {
    email: '',
    password: '',
  }

  const onSubmit = (values: IFormFields, formikHelpers: FormikHelpers<IFormFields>) => {
    login(values).finally(() => {
      formikHelpers.setSubmitting(false)
      history.push('/')
    })
  }

  const validationSchema = yup.object({
    email: yup.string().label('Email').required().email(),
    password: yup.string().label('Password').required(),
  })

  return (
    <div className="mx-auto max-w-xs">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps: FormikProps<IFormFields>) => {
          return (
            <Form className="flex flex-col gap-5 mt-10">
              <p>Super admin login</p>
              <Field
                component={TextField}
                name="email"
                variant="outlined"
                label="Email"
                color="primary"
                required
              />

              <Field
                component={TextField}
                type="password"
                name="password"
                variant="outlined"
                label="Password"
                color="primary"
                required
              />

              <Button
                color="primary"
                variant="contained"
                type="submit"
                disableElevation
                disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
              >
                {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Login'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default LoginPage
