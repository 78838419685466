import { Redirect } from 'react-router'
import LoginPage from '../pages/Auth/LoginPage'
import BudgetRequestPage from '../pages/Company/BudgetRequestPage'
import CompaniesPage from './../pages/Company/CompaniesPage'
import CompanyEditPage from './../pages/Company/CompanyEditPage'
import CompanyViewPage from './../pages/Company/CompanyViewPage'

interface IRoute {
  path: string
  component: any
  authenticated?: boolean
}

const routes: IRoute[] = [
  {
    path: '/',
    component: () => <Redirect to="/budget-request" />,
    authenticated: true,
  },
  {
    path: '/auth/login',
    component: LoginPage,
  },
  {
    path: '/budget-request',
    component: BudgetRequestPage,
    authenticated: true,
  },
  {
    path: '/company',
    component: CompaniesPage,
    authenticated: true,
  },
  {
    path: '/company/edit/:id',
    component: CompanyEditPage,
    authenticated: true,
  },
  {
    path: '/company/view/:id',
    component: CompanyViewPage,
    authenticated: true,
  },
]

export default routes
