import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../components/MainDashboardWrapper/DashboardWrapper'
import { RouteComponentProps } from 'react-router-dom'
import { getOneCompany } from './helper'
import BaseTable from '../../components/BaseTable/BaseTable'
import { Avatar, Tooltip } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'

interface IProps extends RouteComponentProps<{ id: string }> {}

const CompanyViewPage: React.FC<IProps> = ({ match }) => {
  const [company, setCompany] = useState<any>()
  useEffect(() => {
    getOneCompany(match.params.id).then((res) => setCompany(res.data))
  }, [match.params.id])

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'displayName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Roles',
      accessor: 'UserCompanyModel.roles',
      disableSortBy: true,
      Cell: (tableProps: any) => {
        return (
          <AvatarGroup max={5}>
            {tableProps.value.map((item: any, index: number) => (
              <Tooltip key={index} title={`${item}`}>
                <Avatar sizes="10px" alt={`${item}`}>
                  {item.charAt(0).toUpperCase()}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        )
      },
    },
  ]

  const getMonthName = (month: number) => {
    switch (month) {
      case 0:
        return 'January'
      case 1:
        return 'February'
      case 2:
        return 'March'
      case 3:
        return 'April'
      case 4:
        return 'May'
      case 5:
        return 'June'
      case 6:
        return 'July'
      case 7:
        return 'August'
      case 8:
        return 'September'
      case 9:
        return 'October'
      case 10:
        return 'November'
      case 11:
        return 'December'

      default:
        break
    }
  }

  return (
    <DashboardWrapper page="company" title={company?.name || 'Company'}>
      <div className="flex gap-5 flex-wrap">
        <div className="w-72 bg-white max-w-xs rounded-sm overflow-hidden shadow-lg">
          <div className="h-20 bg-gray-400 flex items-center justify-between">
            <p className="mr-0 text-white text-lg pl-5 uppercase">Remaining Budget</p>
          </div>

          <p className="py-4 text-3xl ml-5">${(company?.currentBudget || 0).toLocaleString()}</p>
        </div>
        <div className="w-72 bg-white max-w-xs rounded-sm overflow-hidden shadow-lg">
          <div className="h-20 bg-green-400 flex items-center justify-between">
            <p className="mr-0 text-white text-lg pl-5 uppercase">Utilized Budget</p>
          </div>

          <p className="py-4 text-3xl ml-5">${(company?.utilized || 11).toLocaleString()}</p>
        </div>

        <div className="w-72 bg-white max-w-xs rounded-sm overflow-hidden shadow-lg">
          <div className="h-20 bg-yellow-400 flex items-center justify-between">
            <p className="mr-0 text-white text-lg pl-5 uppercase">Financial year ending month</p>
          </div>

          <p className="py-4 text-3xl ml-5">{getMonthName(company?.FYMonth || 0)}</p>
        </div>
      </div>

      <BaseTable columns={columns} data={company?.employees || []} />
    </DashboardWrapper>
  )
}

export default CompanyViewPage
