import { backendUrl } from './../../env-config'
import { authAxios } from './../../helpers/axiosAuthInterceptor'

export const getAllCompanyBudgetRequests = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(`${backendUrl}/super-admin/get-all-company-budget-requests`)

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const acceptBudgetRequest = async (requestId: string | number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/super-admin/accept-company-budget-request`, {
        requestId,
      })

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const rejectBudgetRequest = async (requestId: string | number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/super-admin/reject-company-budget-request`, {
        requestId,
      })

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const getAllCompanies = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(`${backendUrl}/super-admin/get-all-companies`)

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const getOneCompany = async (companyId: number | string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/super-admin/get-one-company`, {
        companyId,
      })

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}

export const createOrUpdateCompany = async (values: any) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/super-admin/create-or-update-company`, {
        values,
      })

      resolve(res.data)
    } catch (err: any) {
      reject(err?.response?.data?.message || err?.message)
    }
  })
}
